import styled from "styled-components";

export const StyledControls = styled.div`
  display: flex;
`;

export const NextButtons = styled.div``;

export const Spacer = styled.div`
  flex-grow: 1;
`;
